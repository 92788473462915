<template lang="">
  <div>
    <b-card>
      <basetable
        v-if="renderComp"
        :dataurl="dataurl+'?filter[billingable_type]=App\\Model\\Claim&filter[billings.vendor_id]='+user.id"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :is-view="false"
        :filter="filter"
        :title_modal="title_modal"
        :tombol_hide="true"
        :is-edit="false"
        :is-delete="false"
        :is-detail="false"
      >
        <template v-slot:buttonaddon2>
          <b-button
            variant="primary"
            @click.prevent="showTambah"
          >
            <span class="text-nowrap">
              <feather-icon
                icon="PlusIcon"
              />
              Tambah
            </span>
          </b-button>
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status == 'created'"
            class="badge bg-secondary"
          >Dibuat</span>
          <span
            v-else-if="data.item.status == 'submitted'"
            class="badge bg-warning"
          >Diajukan</span>
          <span
            v-else-if="data.item.status == 'approved'"
            class="badge bg-info"
          >Disetujui</span>
          <span
            v-else-if="data.item.status == 'paid'"
            class="badge bg-success"
          >Selesai</span>
          <span
            v-else-if="data.item.status == 'rejected'"
            class="badge bg-danger"
          >Ditolak</span>
          <span v-else>-</span>
        </template>
        <template #cell(action)="data">
          <b-button
            variant="primary"
            size="sm"
            class="mr-0"
            @click="getDetail(data)"
          >
            <feather-icon
              icon="FolderIcon"
            />
          </b-button>
        </template>
      </basetable>
      <b-modal
        id="modal-center"
        ref="my-modal-tambah"
        :title="title_modal"
        centered
        hide-footer
      >
        <div class="form-group">
          <!-- <label>Status</label> -->
          <v-select
            v-model="itemNopol"
            label="name"
            placeholder="Pilih Nomor Klaim"
            :options="optionNopol"
            @input="changeSelectNopol"
          />
        </div>

        <div class="form-group mt-2 float-right">
          <b-button
            class
            variant="primary mr-1"
            @click="hideModal"
          >
            Batal
          </b-button>
          <b-button
            variant="success"
            @click.prevent="saveModal"
          >
            Tambah
          </b-button>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import vSelect from 'vue-select'
import {
  BCard,
  BButton
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
let vendor = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    basetable,
    vSelect,
    BCard,
    BButton
  },
  data() {
    return {
      userType:null,
      user:null,
      dataurl:"/billing",
      baseroute:"operasional-maintenance-item",
      title_modal: this.$route.meta.pageTitle,
      isModal:true,
      title:"Daftar Resiko Pribadi",
      renderComp : true,
      filter:[],
      itemNopol:[],
      optionNopol :[],
      dataTotal:null,
      fields: [
        { key: 'number', label: 'Nomor Penagihan', sortable: true },
        { key: 'billingable.number', label: 'Nomor SPB', sortable: true },
        { key: 'billingable.contract_subject', label: 'Judul SPB', sortable: true },
        { key: 'billingable.contract_number', label: 'Nomor Kontrak', sortable: true },
        { key: 'billingable.region.name', label: 'Region', sortable: true },
        { key: 'billingable.date', label: 'Tanggal Pengajuan', sortable: true },
        // { key: 'used_date', label: 'Tgl Pengambilan', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      saveData:{},
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
    if(this.$route.meta.baseroute)
      this.baseroute = this.$route.meta.baseroute
  },
  mounted(){
    this.getClaim()
  },
  methods:{
      showTambah(){
        this.title_modal = "Tambah"
        this.$refs['my-modal-tambah'].show()
      },
      changeSelectNopol(data){
        if(data != null){
          this.saveData.id = data.id
          this.saveData.name = data.name
        }
      },
      hideModal(){
        this.$refs['my-modal-tambah'].hide()
      },
      saveModal(){
        this.$http.post('/claim/'+this.saveData.id+'/bill').then(()=>{
          this.successNotif()
        }).catch(err=>{
          this.errorSubmit(err)
        })
      },
      successNotif(){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Submit Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$refs['my-modal-tambah'].hide()
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
      },
      errorSubmit(err){
        const msg = err.response.data.message
        this.$refs['my-modal-tambah'].hide()
        this.$bvToast.toast((msg)?msg:'Submit error', {
          title: 'Error',
          solid: true,
          variant:'danger'
        })
      },
      getClaim(){
        this.$http.get('/claim?filter[status]=approved').then(res=>{
          for(var nilai in res.data.data){
            this.optionNopol.push({
              id:res.data.data[nilai].id,
              name:res.data.data[nilai].number
            })
          }
        })
      },
      getDetail(data){
        this.$router.push({name:'own-risk-view', params:{id:data.item.id}})
      }
  },
}
</script>
<style lang="">

</style>
